@font-face {
  font-family: 'NunitoSans';
  src: url('NunitoSans-Light.eot');
  src: url('../fonts/Nunito_Sans/NunitoSans-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Nunito_Sans/NunitoSans-Light.woff2') format('woff2'),
       url('../fonts/Nunito_Sans/NunitoSans-Light.woff') format('woff'),
       url('../fonts/Nunito_Sans/NunitoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../fonts/Nunito_Sans/NunitoSans-Regular.eot');
  src: url('../fonts/Nunito_Sans/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Nunito_Sans/NunitoSans-Regular.woff2') format('woff2'),
       url('../fonts/Nunito_Sans/NunitoSans-Regular.woff') format('woff'),
       url('../fonts/Nunito_Sans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../fonts/Nunito_Sans/NunitoSans-SemiBold.eot');
  src: url('../fonts/Nunito_Sans/NunitoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Nunito_Sans/NunitoSans-SemiBold.woff2') format('woff2'),
       url('../fonts/Nunito_Sans/NunitoSans-SemiBold.woff') format('woff'),
       url('../fonts/Nunito_Sans/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../fonts/Nunito_Sans/NunitoSans-Bold.eot');
  src: url('../fonts/Nunito_Sans/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Nunito_Sans/NunitoSans-Bold.woff2') format('woff2'),
       url('../fonts/Nunito_Sans/NunitoSans-Bold.woff') format('woff'),
       url('../fonts/Nunito_Sans/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../fonts/Nunito_Sans/NunitoSans-Black.eot');
  src: url('../fonts/Nunito_Sans/NunitoSans-Black.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Nunito_Sans/NunitoSans-Black.woff2') format('woff2'),
       url('../fonts/Nunito_Sans/NunitoSans-Black.woff') format('woff'),
       url('../fonts/Nunito_Sans/NunitoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

