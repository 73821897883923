/* Navbar */

.navbar {
  .navbar-brand-wrapper {
    .navbar-brand {
      &.brand-logo-mini {
        display: none;
      }
    }
  }
  .navbar-menu-wrapper {
    .count-indicator {
      position: relative;

      .count-symbol,
      .count-number {
        position: absolute;
        border-radius: 100%;
        border: 2px solid $white;
      }
      .count-symbol {
        top: 22px;
        right: 0px;
        width: 10px;
        height: 10px;
        background: theme-color(danger);
      }
      .count-number {
        min-width: 14px;
        height: 14px;
        font-size: .5rem;
        color: $white;
        bottom: 16px;
        right: -5px;
        line-height: 1;
        text-align: center;
      }
      &:after {
        display: none;
      }
    }
    .dropdown {
      .dropdown-toggle {
        &:after {
          color: $white;
          font-size: 1rem;
        }
      }
      .dropdown-menu {
        @extend .dropdownAnimation;        
      }
    }
    .navbar-nav {
      flex-direction: row;
      align-items: center;
      .nav-item {
        &.dropdown {
          .navbar-dropdown {
            position: absolute;            
            font-size: 0.9rem;
            margin-top: 0;
            right: 0;
            left: auto;
            .rtl & {
              right: auto;
              left: 0;
            }
            padding: 0;

            .dropdown-item {
              @extend .d-flex;
              @extend .align-items-center;
              margin-bottom: 0;
              padding: 11px 13px;

              i {
                font-size: 17px;
              }

              .ellipsis {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .rtl & {
                i{
                  @extend .mr-0;
                  margin-left: 10px;
                }
              }
            }

            .dropdown-divider {
              margin: 0;
            }
          }
          @media (max-width: 991px) {
            position: static;
            .navbar-dropdown {
              left: 20px;
              right: 20px;
              top: $navbar-height;
              width: calc(100% - 40px);
            }
          }
        }
        &.nav-profile {
          .nav-link {
            @extend .d-flex;
            @extend .align-items-center;
            .nav-profile-img {
              position: relative;
              width: 32px;
              height: 32px;
              img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
              }
              .availability-status {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: 2px solid #ffffff;
                bottom: 5px;
                right: -5px;
                &.online {
                  background: theme-color(success);
                }
                &.offline {
                  background: theme-color(danger);
                }
                &.busy {
                  background: theme-color(warning);
                }
              }
            }
            .nav-profile-text {
              margin-right: 1.25rem;
              .rtl & {
                margin-right: 0;
                margin-left: 1.25rem;
              }
              p {
                line-height: 1;
                font-size: 1rem;
              }
              @media (max-width: 767px) {
                display: none;
              }
            }
            &.dropdown-toggle {
              &:after {
                line-height: 2;
              }
            }
          }
        }
      }
      &.navbar-nav-right {
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
  &.default-layout-navbar {
    font-weight: 400;
    background: $navbar-default-bg;
    transition:background $action-transition-duration $action-transition-timing-function;
    -webkit-transition:background $action-transition-duration $action-transition-timing-function;
    -moz-transition:background $action-transition-duration $action-transition-timing-function;
    -ms-transition:background $action-transition-duration $action-transition-timing-function;
    .navbar-brand-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
      width: $sidebar-width-lg;
      height: $navbar-height;
      @media (max-width: 991px) {
        width: 55px;
      }
      .navbar-brand {
        color: lighten(color(gray-dark), 20%);
        font-size: 1.5rem;
        line-height: 48px;
        margin-right: 0;
        padding: .25rem 0 .25rem $sidebar-menu-padding-left;
        width: 100%;
        text-align: left;

        &:active,
        &:focus,
        &:hover {
          color: lighten(color(gray-dark), 10%);
        }

        img {
          width: 130px;
          height: 23px;
          max-width: 100%;
          margin: auto;
          vertical-align: middle;
        }
      }

      .brand-logo-mini {
        padding-left: 0;
        text-align: center;
        img {
          width: calc(#{$sidebar-width-icon} - 50px );
          max-width: 100%;
          margin: auto;
        }
      }
    }

    .navbar-menu-wrapper {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      color: $navbar-menu-color;
      padding-right: 36px;
      .rtl & {
        padding-left: 36px;
        padding-right: 0;
      }
      width: calc(100% - #{$sidebar-width-lg});
      height: $navbar-height;
      @media (max-width: 991px) {
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
      }

      .navbar-toggler {
        border: 0;
        color: inherit;
        height: $navbar-height;
        @include border-radius(0px);
        padding-left: 5px;
        padding-right: 20px;
        &:not(.navbar-toggler-right) {
          font-size: 1.5rem;
          @media (max-width: 991px) {
            display: none;
          }
        }
        &.navbar-toggler-right{
          @media (max-width:991px){
            padding-left: 15px;
            padding-right: 11px;
            border-right: none;
          }
        }
      }

      .search-field {
        .input-group {
          input {
            font-size: $default-font-size;
            padding: .5rem;
            @include input-placeholder {
              font-size: $navbar-font-size;
              color: $navbar-menu-color;
              font-weight: 300;
            }
          }
          i {
            font-size: 17px;
            margin-right: 0;
            color: $navbar-menu-color;
          }
          .input-group-text {
            background: transparent;
          }
        }
        .rtl & {
          @extend .ml-0;
          @extend .mr-4;
        }
      }

      .navbar-nav {
        .nav-item {
          .nav-link {
            color: inherit;
            font-size: $navbar-font-size;
            font-weight: 600;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            height: $navbar-height;
            @include display-flex;
            @include align-items(center);
            @media (max-width: 767px) {
              margin-left: .8rem;
              margin-right: .8rem;
            }
            i {
              font-size: $navbar-icon-font-size;
            }
            .btn-nav-action {
              @extend .btn-gradient-primary;
            }
          }
          &:last-child {
            .nav-link {
              margin-right: 0;
              .rtl & {
                margin-right: 1.5rem;
                margin-left: 0;
              }
            }
          }
          &.nav-logout {
            i {
              margin-left: .5rem;
              .rtl & {
                margin-left: 0;
                margin-right: .5rem;
              }
            }
          }
        }
      }
      .dropdown-menu {
        border: none;
        @include border-radius(5px);
        -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
  &.horizontal-layout-navbar {
    @include display-flex();
    @include flex-direction(row);
    background: $navbar-default-bg;
    padding: 0 2.25rem;
    .navbar-brand-wrapper {
      width: 220px;
      .navbar-brand {
        img {
          width: 140px;
        }
        &.brand-logo-mini {
          img {
            width: 40px;            
          }
        }
      }
    }
    .navbar-menu-wrapper {
      .navbar-nav {
        margin-bottom: 0;
        .nav-item {
          @include display-flex();
          @include align-items(stretch);
          .nav-link {
            color: $horizontal-menu-item-color;
            @extend .d-flex;
            @extend .align-items-center;
            &.active {
              background: rgba($black, .1);
            }
          }
          &.dropdown {
            .dropdown-toggle {
              font-size: $navbar-font-size;
            }
            .dropdown-menu {
              margin-top: 0;
            }
          }
          &.nav-search {
            .nav-link {
              padding-left: 0;
              padding-right: 0;
            }
            .search-field {
              .input-group-text,
              input {
                border: 0;
              }
              .input-group-text {
                font-size: 1.25rem;
                color: $horizontal-menu-item-color;
                background: transparent;
              }
              input {
                padding-left: 0;
                padding-right: 0;
                background: transparent;
                @include input-placeholder {
                  font-size: .875rem;
                  color: $navbar-menu-color;
                }
              }
            }
          }
        }
        &.navbar-nav-left {
          @extend .align-items-stretch;            
          .nav-item {
            .nav-link {
              padding: 1.5rem 1rem;                
            }
          }
        }
        &.navbar-nav-right {
          .nav-item {
            margin-right: 1rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width:991px) {
  .navbar {
    flex-direction: row;
    .navbar-brand-wrapper {
      width: 75px;
      .navbar-brand {
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;
      .brand-logo-mini{
        padding-top: 0px;
      }
    }
  }
}

/* Style for rendering off-canvas menu in mobile and tablet */
@media (max-width: 991px) {
  .navbar {
    &.horizontal-layout-navbar {
      padding: 1rem;
      .navbar-brand-wrapper {
        width: 75px;
      }
      .navbar-menu-wrapper {
        .navbar-collapse {
          z-index: 1;
          position: absolute;
          right: 0;
          background: $white;
          top: 60px;
          width: 50%;          
          &.collapsing,
          &.show {
            display: block;
          }
          .nav-item {
            .nav-link {
              width: 100%;
              &.active {
                border-bottom: 0;              
              }
            }
            &.dropdown {
              display: block;
              .dropdown-menu {
                position: static;
                float: none;
                width: 100%;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

/* Navbar color variations */
@each $color, $value in $theme-gradient-colors {
  .navbar {
    &.default-layout-navbar {
      &.navbar-#{$color} {
        background: $value;
        color: $white;
        .nav-profile-text {
          p {
            @extend .text-white;         
          }
        }
        .search-field {
          .input-group {
            input {
              @include input-placeholder {
                color: $white;
              }
            }
            i {
              color: $white;
            }
          }
        }
        .navbar-nav {
          .nav-item {
            .nav-link {
              .btn-nav-action {
                @extend .btn-outline-#{$color};
              }
            }
          }
        }
      }
    }
  }
}