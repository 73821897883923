/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  font-family: $type1;
  font-weight: 400;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;

    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      .collapse {
        z-index: 999;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-top $sidebar-menu-padding-right $sidebar-menu-padding-bottom $sidebar-menu-padding-left;
        color: $sidebar-light-menu-color;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;

        i {
          color: inherit;

          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-right: 1.5rem;
            .rtl & {
              margin-left: 2rem;
              margin-right: 0;
            }
            color: $sidebar-light-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }

          &.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: .75rem;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-light-menu-arrow-color;
            &:before {
              content: "\F142";
              font-size: inherit;
              color: $sidebar-light-menu-arrow-color;
            }
            + .menu-icon {
              margin-left: .25rem;
              .rtl & {
                margin-left: 0;
                margin-right: .25rem;
              }
            }
          }
        }

        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
          font-weight: 600;
          @include transition(font-weight .4s linear);
        }

        .badge {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }

        &[aria-expanded="true"]{
          .menu-arrow {
            &:before {
              content: "\f140";
            }
          }
        }
      }

      &.active {
        background: $sidebar-light-menu-active-bg;
        > .nav-link {
          i,
          .menu-title,
          .menu-arrow {
            color: $sidebar-light-menu-active-color;
            font-weight: 900;
          }
        }
      }
      &.nav-profile {
        border-bottom: 1px solid $border-color;
        margin-bottom: 1rem;
        .nav-link {
          padding-top: 1.75rem;
          padding-bottom: 1.75rem;
        }
        img {
          width: 32px;
          height: 32px;
          border-radius: 100%;
        }
        .nav-profile-text {
          margin-left: 1.25rem;
          font-size: 1rem;
          font-weight: bold;
          .rtl & {
            margin-right: 1.25rem;
            margin-left: 0;
          }
        }
      }
      &.sidebar-actions {
        margin-top: 3rem;
        .nav-link {
          border-top: 0;
          display: block;
          height: auto;
          padding-top: 10px;
          padding-bottom: 10px;
          &:last-child {
            margin-top: 30px;
          }
        }
        &:hover {
          background: initial;
          .nav-link {
            color: initial;
          }
        }
      }
    }

    &:not(.sub-menu) {
      > .nav-item {
        &:not(.nav-category) {
          > .nav-link {
            &:hover {
              .menu-title,
              .menu-arrow,
              .menu-icon {
                color: $sidebar-light-menu-hover-color;              
                background: $sidebar-light-menu-hover-bg;
                font-weight: bold;
                @include transition(font-weight .4s linear);
              }         
            }
          }
        }
      }
    }
    &.sub-menu {
      margin-bottom: 20px;
      margin-top:0;
      list-style: none;
      padding: $sidebar-submenu-padding;

      .nav-item {
        padding: 0;
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &:before {
            content: "\F374";
            font-family: "Material Design Icons";
            display: block;
            position: absolute;
            left:0px;
            .rtl & {
              left: auto;
              right: 0;
            }
            top:50%;
            @include transform(translateY(-50%));
            color: lighten($sidebar-light-submenu-color, 10%);
            font-size: .75rem;
          }
          &:hover {
            color: $sidebar-light-submenu-hover-color;
            &:before {
              color: $sidebar-light-submenu-hover-color;
            }
          }
          &.active {
            color: $sidebar-light-menu-active-color;
            background: transparent;
            &:before {
              color: $sidebar-light-menu-active-color;
            }
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
//sidebar color variation
.sidebar-dark {
  .sidebar {
    background: $sidebar-dark-bg;
    .nav {
      .nav-item {
        .nav-link {
          color: $sidebar-dark-menu-color;
          i {
            color: inherit;
            &.menu-icon {
              color: $sidebar-dark-menu-icon-color;
            }
            &.menu-arrow {
              color: $sidebar-dark-menu-arrow-color;
              &:before{
                color: inherit;
              }
            }
          }
          .menu-title {
            color: inherit;
          }
        }
        &.active {
          background: $sidebar-dark-menu-active-bg;
          > .nav-link {
              i {
                  color: $sidebar-dark-menu-active-color;                
              }
          }
        }
        &:hover {
          background: $sidebar-dark-menu-hover-bg;
        }
        &.sidebar-actions {
          &:hover {
            background: initial;
            .nav-link {
              color: initial;
            }
          }
        }
      }
      &:not(.sub-menu) {
        > .nav-item {
          &:hover {
            &:not(.nav-category):not(.nav-profile) {
              > .nav-link {
                .menu-icon,
                .menu-title,
                .menu-arrow {
                  color: $sidebar-dark-menu-hover-color;                  
                }
              }
            }
          }
        }
      }
      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-dark-submenu-color;
            &:before {
              color: lighten($sidebar-dark-submenu-color, 10%);
            }
            &.active {
              color: $sidebar-dark-menu-active-color;
              background: transparent;
            }
            &:hover {
              color: $sidebar-dark-submenu-hover-color;
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}